import React from 'react';
import {createRoot} from 'react-dom/client';

import Loadable from 'react-loadable';
import Loader from "components/Loader";

const node = document.getElementById('echo-smartphone-compatibility');

if (node) {

  const Component = Loadable({
    loader: () => import('./index'),
    loading: Loader,
  });

  const root = createRoot(node);
  root.render(<Component/>);

}
