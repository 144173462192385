import React from 'react';
import {createRoot} from 'react-dom/client';

import Loadable from 'react-loadable';
import Loader from "components/Loader";


const node = document.getElementById('echo-sale-countdown');

if (node) {

  const SaleCountdownBox = Loadable({
    loader: () => import('./index'),
    loading: Loader,
  });

  const root = createRoot(node);
  root.render(<SaleCountdownBox/>);

}