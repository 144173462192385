import React from 'react';
import {createRoot} from 'react-dom/client';

import Loadable from 'react-loadable';
import Loader from "components/Loader";


const node = document.getElementById('echo-gkv-finder');

if (node) {

  const GVKFinder = Loadable({
    loader: () => import('./index'),
    loading: Loader,
  });

  const root = createRoot(node);
  root.render(<GVKFinder/>);

}
