import React from 'react';
import {createRoot} from 'react-dom/client';

import Loadable from 'react-loadable';
import Loader from "components/Loader";

const node = document.getElementById('echo-dr-ohr-product-selector');

if (node) {

  const DrOhrProductSelector = Loadable({
    loader: () => import('./index'),
    loading: Loader,
  });

  const root = createRoot(node);
  root.render(<DrOhrProductSelector/>);

}
